<template>
  <v-container fluid>
    <v-row>
      <v-col lg="6" md="6" sm="6">
        <v-select
          v-model="start_month"
          :items="months"
          outlined
          dense
          label="Start Month"
          clearable
          @click:clear="
            start_month = null;
            end_month = null;
          "
        ></v-select>
      </v-col>
      <v-col lg="6" md="6" sm="6">
        <v-select
          v-model="end_month"
          :items="months"
          outlined
          dense
          label="End Month"
          clearable
          @click:clear="end_month = null"
          :disabled="!start_month"
          @change="
            invertDates();
            getLedgerAccounts(start_month, end_month);
          "
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="4" sm="4">
        <v-btn
          @click="downloadRangeIncomeStatement(start_month, end_month)"
          color="primary"
          :disabled="!start_month || !end_month || end_month < start_month"
          >Download <v-icon small right>mdi-download</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../../components/firebaseInit";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatAsCurrency } from "../../../../composables/external";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
export default {
  name: "ExportDateRangeCard",
  props: ["months"],
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      start_month: null,
      end_month: null,
      ledgerTotals: [],
    };
  },
  methods: {
    formatAsCurrency,
    // Ensures 'End Date' is never before 'Start Date
    invertDates() {
      if (this.end_month > this.start_month) return;
      [this.start_month, this.end_month] = [this.end_month, this.start_month];
    },
    async getLedgerAccounts(start, end) {
      this.ledgerTotals = [];
      const ledgerRef = db
        .collectionGroup("ledger_accounts")
        .where("financial_statement", "==", "Income Statement")
        .where("month", ">=", start)
        .where("month", "<=", end);
      try {
        const snapshot = await ledgerRef.get();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          // If ledger is in list --> calculate its total value
          // If ledger isn't in list --> add it
          if (this.ledgerTotals.some((x) => x.account_id === data.account_id)) {
            const index = this.ledgerTotals.findIndex(
              (x) => x.account_id === data.account_id
            );
            this.ledgerTotals[index].total += data.monthly_total;
          } else {
            this.ledgerTotals.push({ ...data, total: data.monthly_total });
          }
        });

        this.ledgerTotals = this.ledgerTotals.sort((a, b) =>
          a.account_name > b.account_name ? 1 : -1
        );
        // Ensure "Sales" ledger appears as first item on statement
        const salesIndex = this.ledgerTotals.findIndex(
          (x) => x.account_name === "Sales"
        );
        const salesLedger = this.ledgerTotals.splice(salesIndex, 1)[0];
        this.ledgerTotals.splice(0, 0, salesLedger);
        // Insert totals for each section
        const accountTypes = ["Revenue", "Cost Of Sales", "Expenses"];
        accountTypes.forEach((type) => {
          const index = this.ledgerTotals.findIndex(
            (el) => el.account_type === type
          );
          // Calculate total of section
          const typeTotal = this.ledgerTotals.reduce((total, item) => {
            // Ensure contra amounts are deducted from totals
            if (item.isContra) item.total = -Math.abs(item.total);
            return item.account_type === type ? total + item.total : total;
          }, 0);
          const insertItem = {
            account_name: `Total ${type}`,
            account_type: type,
            total: typeTotal,
          };
          this.ledgerTotals.splice(index, 0, insertItem);
        });
        // Calculate Gross Profit
        const totalRevenue = this.ledgerTotals.find(
          (x) => x.account_name === "Total Revenue"
        );
        const totalCostOfSales = this.ledgerTotals.find(
          (x) => x.account_name === "Total Cost Of Sales"
        );
        const grossProfit = totalRevenue.total - totalCostOfSales.total;
        const totalOperatingExpenses = this.ledgerTotals.find(
          (x) => x.account_name === "Total Expenses"
        );
        // Calculate Nett Profit
        const nettProfit = grossProfit - totalOperatingExpenses.total;
        // Calculate taxation and dividens
        const taxation = 0;
        const dividends = 0;
        // Calculate Retained Income
        const retainedIncome = nettProfit - taxation - dividends;
        const insertGrossProfit = {
          account_name: "Gross Profit",
          account_type: "Gross Profit",
          total: grossProfit,
        };
        const insertNettProfit = {
          account_name: "Nett Profit",
          account_type: "Nett Profit",
          total: nettProfit,
        };
        const insertRetainedIncome = {
          account_name: "Retained Income",
          account_type: "Retained Income",
          total: retainedIncome,
        };
        this.ledgerTotals.push(
          insertGrossProfit,
          insertNettProfit,
          insertRetainedIncome
        );
      } catch (error) {
        return new Error(error);
      }
    },
    //
    downloadRangeIncomeStatement(start, end) {
      const doc = new jsPDF({
        unit: "mm",
        format: "a4",
        compress: true,
      });
      let startH = 20;
      // Title
      const setTitle = () => {
        doc.setTextColor("black");
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text("Income Statement", 16, startH, "left");
      };

      // Company Details
      const setCompanyDetails = () => {
        const detailText = `${this.company_legal_name} ${
          this.company_trading_as ? "T/A " + this.company_trading_as : ""
        }`;
        doc.setTextColor("black");
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        doc.text(detailText, 16, (startH += 10), "left");
        // Period
        const formatPeriod = (date) => {
          return new Date(date).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
          });
        };
        const periodText = `For the period ${formatPeriod(
          start
        )} until ${formatPeriod(end)}`;
        doc.text(periodText, 16, (startH += 6), "left");
        // Horizontal line
        doc.line(16, (startH += 6), 194, startH);
      };
      // Ledgers
      const setLedgers = () => {
        const accountTypes = [
          "Revenue",
          "Cost Of Sales",
          "Gross Profit",
          "Expenses",
          "Nett Profit",
          "Retained Income",
        ];

        accountTypes.forEach((item, index) => {
          // Add group totals table
          // Add related 'ledger totals' table
          doc.autoTable({
            body: this.ledgerTotals.reduce((list, el) => {
              if (el.account_type === item && el.total !== 0)
                return list.concat([
                  { account_name: el.account_name, total: el.total },
                ]);
              else return list;
            }, []),
            theme: "plain",
            startY: index === 0 ? (startH += 10) : null,
            tableWidth: 180,
            styles: {
              fontSize: 9,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
            columnStyles: { total: { halign: "right" } }, // European countries centered
            didParseCell: (data) => {
              if (
                data.column.dataKey === "total" &&
                data.cell.section === "body"
              ) {
                data.cell.text = formatAsCurrency("R", data.cell.text);
              }
              // Find row index of cells that include "Total"
              // Apply styles to row
              if (data.row.index === 0) {
                data.cell.styles.fontStyle = "bold";
                data.cell.styles.fontSize = 12;
              }
            },
          });
        });
      };
      // Add page numbers
      const addPageNumbers = () => {
        //Page count
        var pageCount = doc.internal.getNumberOfPages();
        var i = 0;
        for (i = 0; i < pageCount; i++) {
          doc.setPage(i);
          doc.setFontSize(9);
          doc.text(
            186,
            290,
            `Page ${
              doc.internal.getCurrentPageInfo().pageNumber + " of " + pageCount
            }`
          );
        }
      };
      // Call functions
      setTitle();
      setCompanyDetails();
      setLedgers();
      addPageNumbers();
      // Save and download
      const testMode = false;
      testMode
        ? doc.output("dataurlnewwindow")
        : doc.save(`income-statement${start}-${end}.pdf`);
    },
  },
};
</script>